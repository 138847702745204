body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  --title-color:#fe539a;
  /* color: ; */
}

/* Heading 1 */
h1{
  font-size: 3rem;
  padding: 2rem 0;
}
.title-color{
  text-align: center;
  background-color: var(--title-color);
  color: white;
}
/* Nav  */
nav{
  box-shadow: 2px 2px 3px rgb(188, 184, 184);
}
.fa-xmark{
  font-size: 1.6665rem;
}
.navbar-toggler{
  box-shadow: none;
  border: none;
}
.navbar-toggler:focus{
  box-shadow: none;
}

/* Book an Appoinment  */
.appointment:hover
{
  background-color: #fe539a;
  /* background-color: #f5d3e1; */
  color: white;
  border-radius: 0.50rem;
}


/* Choose  */
/* .chExp{
  width: 100%;
  aspect-ratio: 3/3;
  object-fit: contain;
} */
.review-table{
  border: 2px solid red;
}
.r-t{
  display: flex;
  justify-content: center;
}
.menu{
  color: black;
}
.menu.selected{
  color: #fe539a;
}

.footer-icon{
  width: 7%;
  height: 5%;
}

/* Founder Image  */
.centered {
  display: block;
  margin: 1rem auto !important;
}
/* Offer  */
.offer_img{
  width: 100%;
  height: 70%;
}
/* Contact  */
.form-control:focus{
  box-shadow: none;
  border-color: #fe539a;
}
.contact:hover{
  text-decoration: underline;
}
@media(max-width:991px){
  .footer-icon{
    width: 12%;
  }
  .serImg{
    width: 60% !important;
    display: block !important;
    margin: 2rem auto  !important;
  }

}


@media(max-width:450px){
  .logo{
    width: 40%;
    margin-left: 2rem !important;
  }
  h1{
    font-size: 2rem;
    padding: 1.2rem 0;
  }
  .chExp{
    width: 90%;
  }
  .offer_img{
    width: 90%;
    height: 80%;
  }
  .appointment{
    font-size: 1.25rem !important;
    padding: 0.5rem 0.75rem !important;
  }
  
}